import { ViewMode } from '@audit'
import { exhaustiveMatchingGuard } from '~/utils/exhaustiveMatchingGuard'

export function localizeViewMode(viewMode: ViewMode) {
  if (viewMode === ('[both]' as ViewMode)) viewMode = ViewMode.Both

  switch (viewMode) {
    case ViewMode.Both:
      return 'Both'
    case ViewMode.Review:
      return 'Review'
    case ViewMode.TenancySchedule:
      return 'Tenancy Schedule'
    default:
      return exhaustiveMatchingGuard(viewMode)
  }
}
